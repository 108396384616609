import React from "react";
import { linkResolver } from "../../utils/linkResolver";
import "./style.css";

export default ({ title, subtitle, teaser, link, btnText, image, alt }) => (
  <React.Fragment>
    <div className="benefitL-box-wrapper">
      <div className="benefitL-content-wrapper">

        <div className="benefitL-image-wrapper">
          <img src={ image } alt={ alt } className="benefitL-box-image" />
        </div>

        <div className="benefitL-info-wrapper">
          <div className="benefitL-titles-wrapper">
            <h2 className="benefitL-header-bold text-xlt">
              { title }
            </h2>
            <h3 className="benefitL-header-subtitle">
              { subtitle }
            </h3>
          </div>
          <p className="benefitL-teaser-text text-reg">
            { teaser }
          </p>
          <a className="benefitL-link-button text-reg" href={linkResolver(link)}>
            { btnText }
          </a>
        </div>

      </div>
    </div>
  </React.Fragment>
)