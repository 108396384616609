import React from "react";
import { linkResolver } from "../../utils/linkResolver";
import "./style.css";

export default ({ title, subtitle, teaser, link, btnText, image, alt }) => (
  <React.Fragment>
    <div className="benefitR-box-wrapper">
      <div className="benefitR-content-wrapper">

        <div className="benefitR-info-wrapper">
          <div className="benefitR-titles-wrapper">
            <h2 className="benefitR-header-bold text-xlt">
              {title}
            </h2>
            <h3 className="benefitR-header-subtitle">
              {subtitle}
            </h3>
          </div>
          <p className="benefitR-teaser-text text-reg">
            {teaser}
          </p>
          <a className="benefitR-link-button text-reg" href={linkResolver(link)}>
            {btnText}
          </a>
        </div>

        <div className="benefitR-image-wrapper">
          <img src={image} alt={alt} className="benefitR-box-image" />
        </div>

      </div>
    </div>
  </React.Fragment>
)