import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import "../components/NavBar/style.css";
import Footer from "../components/Footer";
import "../components/Footer/style.css";
import Hero from "../components/HomeHero";
import "../components/HomeHero/style.css";
import BenefitRight from "../components/BenefitRight";
import "../components/BenefitRight/style.css";
import BenefitLeft from "../components/BenefitLeft";
import "../components/BenefitLeft/style.css";
import Feature from "../components/Feature";
import "../components/Feature/style.css";
import Contact from "../components/Contact";
import "../components/Contact/style.css";
import Logo from "../components/Logo";
import "../components/Logo/style.css";
import SEO from "../components/SEO";

class IndexPage extends Component {

  render() {

    const {
      data: { homepage, benefit, feature, logo, contact },
    } = this.props

    const heroData = homepage.data;
    const contactData = contact.primary.cta.document.data;

    return(
      <React.Fragment>
        <SEO title="Brandbox" />
        <div className="page-container home-page">
          <div className="home-hero-diagonal"></div>
            <NavBar />
            <Hero
              title={ heroData.home_page_title.text }
              teaser={ heroData.hero_teaser.text }
              teaserColor={ heroData.hero_teaser_color.text }
              image={ heroData.hero_image.url }
              alt={ heroData.hero_image.alt }
              link={ heroData.hero_button_link.uid }
              btnText={ heroData.hero_button_label.text }
              subline1={ heroData.hero_subline_1.text }
              subline2={ heroData.hero_subline_2.text }
              />
          {benefit.items.map((b, i) => {
            const benefitData = b.benefit.document.data;
            if (i%2 === 0) {
              return (
                <BenefitRight
                  title={ benefitData.benefit_title.text }
                  subtitle={ benefitData.benefit_subtitle.text }
                  teaser={ benefitData.benefit_teaser_text.text }
                  link={ benefitData.benefit_link.uid }
                  btnText={ benefitData.benefit_button_label.text }
                  image={ benefitData.benefit_image.url }
                  alt={ benefitData.benefit_image.alt }
                  />
              )
            } else {
                return (
                  <BenefitLeft
                    title={ benefitData.benefit_title.text }
                    subtitle={ benefitData.benefit_subtitle.text }
                    teaser={ benefitData.benefit_teaser_text.text }
                    link={ benefitData.benefit_link.uid }
                    btnText={ benefitData.benefit_button_label.text }
                    image={ benefitData.benefit_image.url }
                    alt={ benefitData.benefit_image.alt }
                    />
                )
            }
          })}
          <div className="feature-section-wrapper">
            {feature.items.map((f, i) => {
              const featureData = f.features.document.data;
              return (
                <Feature
                  title={ featureData.feature_title.text }
                  teaser={ featureData.feature_teaser.text }
                  image={ featureData.feature_image.url }
                  alt={ featureData.feature_image.alt }
                  />
              )
            })}
          </div>
          <Contact
            title={ contactData.contact_field_title.text }
            teaser={ contactData.contact_field_teaser.text }
            portalId={ contactData.hubspot_portal_id.text }
            formId={ contactData.hubspot_form_id.text }
            />
          <div className="logo-section-wrapper">
            {logo.items.map((l, i) => {
              const logoData = l.logo_block.document.data;
              return (
                <Logo
                  image={ logoData.logo_image.url }
                  alt={ logoData.logo_image.alt }
                  />
              )
            })}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        home_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        hero_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        hero_teaser_color: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        hero_button_label: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        hero_button_link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
        hero_image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        hero_subline_1: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        hero_subline_2: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    benefit: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    feature: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    logo: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        cta: PropTypes.shape({
          document: PropTypes.array.isRequired
        }),
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query homePageHero {
    
    homepage: prismicHomePage {
      data {
        home_page_title {
          text
        }
        hero_teaser {
          text
        }
        hero_teaser_color {
          text
        }
        hero_button_label {
          text
        }
        hero_button_link {
          uid
        }
        hero_image {
          url
          alt
        }
        hero_subline_1 {
          text
        }
        hero_subline_2 {
          text
        }
      }
    }

    benefit: prismicHomePageBodyBenefitBlock {
      items {
        benefit {
          document {
            ... on PrismicBenefitBlo {
              id
              data {
                benefit_button_label {
                  text
                }
                benefit_image {
                  url
                  alt
                }
                benefit_link {
                  uid
                }
                benefit_subtitle {
                  text
                }
                benefit_teaser_text {
                  text
                }
                benefit_title {
                  text
                }
              }
            }
          }
        }
      }
    }

    feature: prismicHomePageBodyFeatureBlock {
      items {
        features {
          document {
            ... on PrismicFeature {
              id
              data {
                feature_image {
                  url
                  alt
                }
                feature_teaser {
                  text
                }
                feature_title {
                  text
                }
              }
            }
          }
        }
      }
    }

    logo: prismicHomePageBodyLogoBlock {
      items {
        logo_block {
          document {
            ... on PrismicLogo {
              id
              data {
                logo_image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }

    contact: prismicHomePageBodyCta {
      primary {
        cta {
          document {
            ... on PrismicCta {
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_form_id {
                  text
                }
                hubspot_portal_id {
                  text
                }
              }
            }
          }
        }
      }
    }

  }
`