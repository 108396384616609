import React from "react";
import "./style.css";

export default ({ title, teaser, image, alt }) => (
  <React.Fragment>
    <div className="feature-block-wrapper">
      <div className="feature-content-wrapper">
        <img src={ image } alt={ alt }  className="feature-block-image" />
        <h3 className="feature-block-title">
          { title }
        </h3>
        <p className="feature-block-teaser text-reg">
          { teaser }
        </p>
      </div>
    </div>
  </React.Fragment>
)