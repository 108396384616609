import React from "react";
import "./style.css";
// import { linkResolver } from "../../utils/linkResolver";
// import colorStrip from "../../images/brandbox-color-strip.png";

export default ({ title, teaser, teaserColor, image, alt, subline1, subline2 }) => (
  <React.Fragment>
    <div className="home-hero-wrapper">
      <h1 className="home-hero-title text-med">
        { title }
      </h1>
      {/* <img src={ colorStrip } alt="Color Strip" className="home-hero-colors" /> */}
      <img src={ image } alt={ alt } className="home-hero-image" />
      <div className="home-hero-teaser-wrapper">
        <h5 className="home-hero-teaser">
          { teaser }
        </h5>
        <h5 className="home-hero-teaser-color">
          { teaserColor }
        </h5>
      </div>
      {/* <a className="home-hero-button" href={linkResolver(link)}>
        { btnText }
      </a> */}
    </div>
    <div className="home-hero-subwrapper text-med">
      <h3 className="home-hero-subline1">
        { subline1 }
      </h3>
      <h3 className="home-hero-subline2">
        { subline2 }
      </h3>
    </div>
  </React.Fragment>
)